import { map, Observable, startWith } from 'rxjs';
import { LibraryService } from '../library/library.service';
import { StoreItem } from '../playfab/client/store-item';
import { Price } from '../shopping-cart/price';
import { ITEM_NOT_FOUND } from './catalog-errors';
import { CatalogItemBannerMetaData } from './catalog-item-banner-meta-data';
import { CatalogItemContext } from './catalog-item-context';
import { CatalogItemMetaData } from './catalog-item-meta-data';

export class StoreItemContext {
    constructor(
        public item: StoreItem,
        itemsMap: Map<string, CatalogItemContext>,
        private libraryService: LibraryService
    ) {
        if (!itemsMap.has(item.ItemId)) {
            throw new Error(ITEM_NOT_FOUND);
        }

        const catalogItem = itemsMap.get(item.ItemId);
        if (!catalogItem) {
            throw new Error(ITEM_NOT_FOUND);
        }

        this.owned = this.libraryService.user.pipe(
            map(y => y.Inventory.map(w => w.ItemId).includes(this.itemId)),
            startWith(false)
        );

        this.catalogItem = catalogItem;
        this.meta = this.catalogItem.meta;
        this.itemId = this.item.ItemId;
        this.displayName = this.catalogItem.item.DisplayName;
        this.tags = this.catalogItem.item.Tags;
        this.price = {
            amount: catalogItem.discountPrice,
            originalAmount: catalogItem.price,
            currencyCode: catalogItem.currencyCode
        };
    }

    public catalogItem: CatalogItemContext;
    public meta: CatalogItemMetaData | CatalogItemBannerMetaData;
    public itemId: string;
    public tags: string[];
    public displayName: string;
    public price: Price;
    public owned: Observable<boolean>;
}
