import { map, Observable } from 'rxjs';
import { INVALID_META_DATA } from '../app-errors';
import { LibraryService } from '../library/library.service';
import { MediaPathResolver } from '../media/media-path-resolver';
import { CatalogItem } from '../playfab/client/catalog-item';
import { CartItem } from '../shopping-cart/cart-item';
import { CatalogItemBannerMetaData } from './catalog-item-banner-meta-data';
import { CatalogItemMetaData } from './catalog-item-meta-data';

const EMPTY_JSON = '{}';

export class CatalogItemContext {
    constructor(public item: CatalogItem, private libraryService: LibraryService) {
        this.meta = JSON.parse(item.CustomData || EMPTY_JSON) as CatalogItemMetaData;
    }

    public meta: CatalogItemMetaData | CatalogItemBannerMetaData;

    public get itemId(): string {
        return this.item.ItemId;
    }

    public get deliveryType(): string {
        const meta = this.meta as CatalogItemMetaData;
        return meta.deliveryType ?? 'key';
    }

    public get discount(): number {
        const meta = this.meta as CatalogItemMetaData;
        return Number.parseFloat(meta.discount || '0') ?? 0;
    }

    public get displayName(): string {
        return this.item.DisplayName;
    }

    public get tags(): string[] {
        return this.item.Tags;
    }

    public get price(): number {
        return this.item.VirtualCurrencyPrices.RM / 100;
    }

    public get currencyCode(): string {
        return 'EUR';
    }

    public get discountPrice(): number {
        const discountPrice =
            this.discount > 0
                ? this.truncateByDecimalPlace(((100.0 - this.discount) / 100.0) * this.price, 2)
                : this.price;

        return discountPrice;
    }

    public get owned(): Observable<boolean> {
        return this.libraryService.user.pipe(
            map(x => x.Inventory.map(x => x.ItemId).includes(this.itemId))
        );
    }

    public get ownedSnapshot(): boolean {
        return this.libraryService.userSnapshot.Inventory.map(x => x.ItemId).includes(this.itemId);
    }

    public toCartItem(resolver: MediaPathResolver): CartItem {
        const meta = this.meta as CatalogItemMetaData;
        if (!meta?.host) {
            throw new Error(INVALID_META_DATA);
        }

        return {
            id: this.itemId,
            name: this.displayName,
            coverUrl: resolver.resolve({
                host: meta.host,
                path: meta.path,
                name: meta.cartImage,
                localize: false
            }),
            price: {
                amount: this.discountPrice,
                originalAmount: this.price,
                currencyCode: this.currencyCode
            },
            quantity: 1,
            link: `/store/p/${this.itemId}`
        };
    }

    private truncateByDecimalPlace(value: number, numDecimalPlaces: number): number {
        return Math.trunc(value * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces);
    }
}
