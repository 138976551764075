import { Injectable } from '@angular/core';
import { filter, firstValueFrom, from, map, Observable, shareReplay, switchMap, take } from 'rxjs';
import { UNAUTHORIZED } from '../auth/auth-errors';
import { AuthService } from '../auth/auth.service';
import { LibraryService } from '../library/library.service';
import { PlayFabClient } from '../playfab/client/playfab-client';
import { CatalogContext } from './catalog-context';
import { CATALOG_NOT_FOUND } from './catalog-errors';
import { CatalogItemContext } from './catalog-item-context';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CatalogService {
    private catalogs: Map<string, CatalogContext> = new Map();
    private itemsSource: Map<string, Observable<Map<string, CatalogItemContext>>> = new Map();

    constructor(
        private readonly client: PlayFabClient,
        private readonly authService: AuthService,
        private readonly libraryService: LibraryService
    ) {
        this.preHeat();
    }

    /**
     * This method will prefetch all items from known catalogs, so they remain cached for subsequent calls.
     */
    private async preHeat() {
        const bannerCatalog = environment.playfab.bannerCatalog;
        const gamesCatalog = environment.playfab.productCatalog;

        const banners = firstValueFrom(this.getCatalogItems(bannerCatalog));
        const games = firstValueFrom(this.getCatalogItems(gamesCatalog));

        await Promise.all([games, banners]);
    }

    public getCatalogItems(catalogVersion: string): Observable<Map<string, CatalogItemContext>> {
        if (this.itemsSource.has(catalogVersion)) {
            const source = this.itemsSource.get(catalogVersion);
            if (!source) {
                throw new Error('ItemsSource must not be empty');
            }

            return source;
        }

        const observable = this.authService.user.pipe(
            filter(x => !!x),
            switchMap(x => {
                if (!x) {
                    throw Error(UNAUTHORIZED);
                }

                return from(
                    this.client.getCatalogItems(
                        {
                            CatalogVersion: catalogVersion
                        },
                        x.login
                    )
                );
            }),
            map(x => x.Catalog),
            map(x => x.map(y => new CatalogItemContext(y, this.libraryService))),
            map(x => new Map(x.map(y => [y.item.ItemId, y]))),
            take(1), // We need to actually trigger the sequence once, or all subscribers will do it concurrently, not taking advantage of the replay operator.
            shareReplay(1)
        );

        this.itemsSource.set(catalogVersion, observable);
        return this.getCatalogItems(catalogVersion);
    }

    public getCatalog(catalogVersion: string): CatalogContext {
        if (this.catalogs.has(catalogVersion)) {
            const catalog = this.catalogs.get(catalogVersion);
            if (!catalog) {
                throw new Error(CATALOG_NOT_FOUND);
            }

            return catalog;
        }

        const catalog = new CatalogContext(
            catalogVersion,
            this.client,
            this.authService,
            this.libraryService,
            this
        );

        this.catalogs.set(catalogVersion, catalog);
        return catalog;
    }
}
