import { from, map, Observable } from 'rxjs';
import { LibraryService } from '../library/library.service';
import { LoginResult } from '../playfab/client/login-result';
import { PlayFabClient } from '../playfab/client/playfab-client';
import { CatalogContext } from './catalog-context';
import { CatalogItemContext } from './catalog-item-context';
import { StoreItemContext } from './store-item-context';

export class StoreContext {
    constructor(
        private storeId: string,
        private login: LoginResult,
        private client: PlayFabClient,
        private catalog: CatalogContext,
        private libraryService: LibraryService,
        private itemsMap: Map<string, CatalogItemContext>
    ) {
        this.items = from(
            this.client.getStoreItems(
                {
                    CatalogVersion: this.catalog.catalogVersion,
                    StoreId: this.storeId
                },
                this.login
            )
        ).pipe(
            map(x =>
                x.Store.filter(x => this.itemsMap.has(x.ItemId)).map(
                    y => new StoreItemContext(y, this.itemsMap, this.libraryService)
                )
            )
        );
    }

    public items: Observable<StoreItemContext[]>;
}
