import { filter, from, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { UNAUTHORIZED } from '../auth/auth-errors';
import { AuthService } from '../auth/auth.service';
import { LibraryService } from '../library/library.service';
import { PlayFabClient } from '../playfab/client/playfab-client';
import { STORE_NOT_FOUND } from './catalog-errors';
import { CatalogItemContext } from './catalog-item-context';
import { CatalogService } from './catalog.service';
import { StoreContext } from './store-context';

export class CatalogContext {
    private stores: Map<string, StoreContext> = new Map();

    constructor(
        public readonly catalogVersion: string,
        private readonly client: PlayFabClient,
        private readonly authService: AuthService,
        private readonly libraryService: LibraryService,
        readonly catalogService: CatalogService
    ) {
        this.itemsMap = catalogService
            .getCatalogItems(catalogVersion)
            .pipe(tap(x => (this.itemsMapSnapshot = x)));
    }

    public itemsMap: Observable<Map<string, CatalogItemContext>>;
    public itemsMapSnapshot: Map<string, CatalogItemContext>;

    public getStore(storeId: string): Observable<StoreContext> {
        if (this.stores.has(storeId)) {
            const store = this.stores.get(storeId);
            if (!store) {
                throw new Error(STORE_NOT_FOUND);
            }
            return of(store);
        }

        return this.itemsMap.pipe(
            map(x => {
                const login = this.authService.userSnapshot?.login;
                if (!login) {
                    throw new Error(UNAUTHORIZED);
                }

                const store = new StoreContext(
                    storeId,
                    login,
                    this.client,
                    this,
                    this.libraryService,
                    x
                );
                this.stores.set(storeId, store);
                return store;
            })
        );
    }
}
